<template>
  <div>
    <h3>Physical Quantities and Units</h3>
    <p>
      The quantities that can be measured and which can describe various physical happenings or laws of physics are called physical quantities.

      The unit of a physical quantity is a widely accepted standard to measure and compare the physical
      quantity.
    </p>
    <h5>1. Fundamental and Derived Quantities </h5>
    <p>
      A physical quantity that cannot be expressed in terms of any other physical quantity is called a fundamental
      quantity. The unit of such a quantity is known as a fundamental unit. For example, mass is a fundamental quantity
      as it cannot be expressed or described in terms of any other physical quantity. Accordingly, the unit of mass, \(kg\), is a fundamental unit.
    </p>
    <p>
      Quantities that are expressed in terms of fundamental quantities are known as derived quantities and their
      units are called derived units. For example, density is a derived quantity as it is defined as mass per unit volume.
      Accordingly, the unit of density, \(kg/m^3\), is a derived unit.
    </p>
    <h5>2. System of Units</h5>
    <p>
      A complete set of units comprising both fundamental and derived units is called a system of units. Every
      system of unit has a different set of fundamental and derived quantities. However, in most of the system of
      units in physics mass, length and time are chosen as the fundamental quantities. Some of the common system
      of
      units and the units accepted for length, mass and time in that system of units are mentioned below.
    </p>

    <ul class="a">
      <li>
        <b>CGS System</b> &mdash; In CGS system, centimeter(cm), gram(g) and second (s) are the respective units for length, mass and time.
      </li>
      <li>
        <b>FPS System</b> &mdash; In FPS system, foot(ft), pound(lbs) and second(s) are the respective units for length, mass and time.
      </li>
      <li>
        <b>MKS System</b> &mdash;
        In MKS System, meter (m), kilogram(kg) and second(s) and respective units for length, mass and time.
      </li>
      <li>
        <b>SI System</b> &mdash;
        SI is the abbreviation of French word Système International which means international
        system of units. It is a standard unit system that is accepted worldwide and helps to avoid confusion.
        It has seven fundamental quantities which, along with their units, are described below.
        <br><br>
        <v-layout align-center justify-center>
          <table>
            <tr>
              <th width="400">
                Fundamental Quantity
              </th>
              <th>Unit</th>
            </tr>
            <tr>
              <td>Length</td>
              <td>Metre</td>
            </tr>
            <tr>
              <td>Mass</td>
              <td>Kilogram</td>
            </tr>
            <tr>
              <td>Time</td>
              <td>Second</td>
            </tr>
            <tr>
              <td>Electric current</td>
              <td>Ampere</td>

              </td>
            </tr>
            <tr>
              <td>Thermodydnamic temperature</td>
              <td>Kelvin</td>
            </tr>
            <tr>
              <td>Amount of substance </td>
              <td>Mole</td>
            </tr>
            <tr>
              <td>Luminous intensity</td>
              <td>Candela</td>
            </tr>
          </table>
        </v-layout>
      </li>
    </ul>
    <h5>3. Supplementary Quantities</h5>
    <p>
      Besides fundamental quantities and derived quantities there are two other quantities known as supplementary
      quantities. The units of supplementary quantities are called supplementary units. The two supplementary
      quantities are plane angle and solid angle.
    </p>
    <ol>
      <li>Plane angle- It is the angle that the arc of a circle subtends at its center. Its unit is radian.</li>
      <li>
        Solid angle - It is the angle that a part of a sphere subtends at its center. Its unit is steradian.
      </li>
    </ol>

    <h3>Dimensional Analysis</h3>
    <p>
      While expressing a derived quantity in terms of fundamental quantities, it is written as a product of
      fundamental quantities raised to different powers. These powers to which a base quantity is raised to
      express it in terms of fundamental quantities are called its dimensions.
      <br><br> The dimensions of mass length and time are described as [M], [L] and [T] respectively. For example, velocity as
      we know is the ratio of distance travelled by time i.e.,
      $$\text{Velocity}= \frac{\text{Distance travelled}}{\text{Time}}$$
      Thus, the dimensions of velocity is  given as \([L]/[T]\) i.e., \([M^0LT^{-1}]\).
    </p>
    <p>
      Dimensional analysis helps us to check the correct correctness of a formula and also to derive new formulas
      for research purpose, although it has some limitations as no work related to the magnitude of the quantities
      can be done.
    </p>
    </p>


    <h3>Measurement of length</h3>
    <p>
      Let us have a look on different methods that we use for measuring lengths. Depending on the range of length,
      there are different methods to measure it. For example we use a metre scale to measure length in the range
      of \(10^{-3}\)m to
      \(10 ^{2}\)m , a vernier calipers can measure lengths upto an accuracy of
      \(10^{-4} \)m, a screw gauge can be used to measure lengths up to \(10^{-5}\)m, but to
      measure lengths not in these ranges, we need some special methods for example to measure large distances such
      as the distance of planet or star from earth we use parallax method and to measure extremely small
      distances, for example the size of a molecule we use electron beams.
    </p>
    <p>
      For measuring different ranges of lengths, there are some special units which help us in measuring
      very large and short lengths. Some of them are given below
    </p>
    <ul>
      <li>
        Astronomical unit (A.U.)- One astronomical unit is equal to the average distance between the Earth and
        the Sun.
        <br>1 A.U = 1.496 x 10<sup>11</sup> m
      </li>
      <li>
        Light year- One light year is the distance that light travels in vacuum in one year.
        <br> 1 light year = 9.46 x 10<sup>15</sup> m
      </li>
      <li>
        Parsec- It is the distance at which the average radius of Earth orbit subtends an angle of 1 arc second
        at the centre of the sun.
        <br>1 parsec = 3.08 x 10<sup>16</sup> m
      </li>
      <li>
        Fermi (f)- 1 f = 10<sup>-15</sup> m
      </li>
      <li>
        Armstrong (Å)- 1 Å= 10<sup>-10</sup>
      </li>
    </ul>
    <h3>
      Measurement of mass
    </h3>
    <p>
      While mass of the commonly available objects can be measured by a normal balance, we need special methods to
      measure the mass of extremely large and small objects. For example, mass of very large objects such as
      planets, stars etc. are measured with the help of gravitational method in which their gravitational effect
      of other objects are analyzed. However, for measuring mass of very small objects such as atomic of subatomic
      particles, mass spectrograph is used.
      <br><br> The mass of of atomic particles is expressed in a unit called
      atomic mass unit (a.m.u or u) which is equal to \(\frac{1}{12}^{th}\) the mass of one carbon-12 atom and its
      value is given as,
      $$1 \; a.m.u=1.66 \times 10^{-27} kg $$
    </p>
    <h3>Measurement of time </h3>
    <p>
      Any time interval is measured with the help of a clock. Now a days, a cesium clock is used in the national
      standards which is based on the periodic vibrations produced in a cesium atom. One second is the time
      interval
      for 91,926,311,770 vibrations of radiation in a Cs-133 atom. The radiation corresponds to the transition
      between the two hyperfine levels of a Cs-133 atom in ground state.
    </p>

    <h3>Errors</h3>
    <p>
      Whenever we do a measurement there is always a slight difference between the true and measured value of the
      quantity. The
      result of every experiment contains some uncertainty. These uncertainties in the measured value of the
      experiment are called errors.
      <br>Errors are broadly classified into systematic errors and random errors.
    </p>
    <ol>
      <li>
        Systematic errors- These errors are consistently in one direction i.e., either the measured value is
        always greater than or less than the true value in all the measurements of the same thing experiment. On
        repeating the same experiment again, we get the same error. Systematic errors can be caused due to
        faulty
        equipment, personal biasness or carelessness of the person doing experiment or even due to imperfection
        in
        experimental technique or procedure.
      </li>
      <li>
        Random errors - As the name suggests random errors completely unpredictable. On repeating the same
        experiment again it is very unlikely that we will get the same error as noticed in the previous
        experiments.
      </li>
    </ol>
    <p>
      To minimise errors, same experiment is repeated a large number of times, then the arithmetic mean of all the
      observations is calculated. This arithmetic mean is the most accurate value of the measured quantity and is
      considered as the true value.
      If a physical quantity is measured n times and \(a_1, a_2,...,a_n\) are the measured values, its mean value
      \(a_m\) is given as,
    </p>
    $$ a_m=\frac{a_1+a_2+...+a_n}{n} $$
    <h4>Absolute Error</h4>
    It is equal to the magnitude of the difference between the individual measurements and the true value of the
    quantity.
    Thus, absolute errors are given by
    $$|∆a_1|=|a_m-a_1|
    \\|∆a_2|=|a_m-a_2|
    \\............
    \\ |∆a_n|=|a_m-a_n| $$
    <h4>Mean absolute error</h4>
    The arithmetic mean of absolute errors of all the measurements is called mean absolute error. It is denoted by
    \(∆a_{mean}\).
    $$∆a_{mean}=\frac{|∆a_1|+|∆a_2|+...+|∆a_n|}{n}$$
    Thus, the final result of a measurement is written as, \(a=a_m ± ∆a_{mean}\).
    <br>This means that \(a\) lies in the range of \(a_m -∆a_{mean}\) and \(a_m + ∆a_{mean}\).
    <h4>Relative error and Percentage error</h4>
    <p>
      Ratio of mean absolute error to the mean value is called relative error.
      $$\text{Relative error}= \frac{∆a_{mean}}{a_m}$$
      Relative expressed in the form of percentage is called percentage error.
      $$ \text{Percentage error}= \frac{∆a_{mean}}{a_m} \times 100 \% $$
    </p>

    <h3>Significant Figures</h3>
    <p>
      Since the results of measurements contains some errors, the results are reported in a way that determines the
      accuracy of the measured value and this is done with the help of significant figures.
      In a reported vaue, significant figures are the digits that are absolutely correct plus the first uncertain
      digit. More number of significant figures implies more accuracy of the measured value.
    </p>
    Rules for determining significant figures are given below.
    <ul>
      <li>
        All non-zero digits are significant.
        <br>Example- 22467 has 5 significant figures.
      </li>
      <li>
        All zeroes that occur between two non-zero digits are significant.
        <br>Example-20.07 has 4 significant figures.
      </li>
      <li>
        Trailing zeroes after decimal places are significant.
        <br>Example- 20.47000 has 7 significant figures.
      </li>
      <li>
        For a number without a decimal, trailing zeroes are not significant.
        <br>Example- 3670000 has 3 significant figures.
      </li>
      <li>
        Leading zeroes to the left of a number are not significant.
        <br>Example- 0.006 has 1 significant figure.
      </li>
      <li>
        While adding or subtracting two quantities the result is rounded off and reported in the number of
        decimal places present in the number having the least number of decimal places.
        <br>Example- The result of 0.426 + 4.54 is rounded off to 2 decimal places (4.54 has 2 decimal places).
        Therefore, the result is 4.97.
      </li>
      <li>
        While multiplying or subtracting two numbers the result is round off and reported in the number of
        significant figures present in the number having the least number of significant figures.
        <br>Example- The result of 200 x 4.2 is rounded off to 1 significant figure (200 has the least number of
        significant figures that is 1). Therefore, the result is 800.
      </li>
    </ul>
    <v-responsive>
      <v-layout align-center justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>

<script>
import Boxes from './Boxes.js'

export default {
  name: 'BernoulliPrinciple',
  data () {
    return {
      v1: 1,
      P1: 1,
      rho: 1,
      v2: undefined,
      P2: undefined,
    }
  },
  created: function () {
    // Store mutations
    let title = 'Conservation of Angular Momentum';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: ' Statement of Bernoulli\'s Principle', img:'/assets/number-1.svg',action: () => this.goto('intro')},
      {title: 'Derivation of Bernoulli\'s Principle', img:'/assets/number-2.svg',action: () => this.goto('derivation')},
      {title: 'Continuity Equation', img:'/assets/number-3.svg',action: () => this.goto('continuity')},
      {title: 'Conditions for Applicability', img:'/assets/number-4.svg',action: () => this.goto('appl')},
      {title: 'PlayGraph: Fluid Flow in a Vertical Duct', img:'/assets/touch.svg',action: () => this.goto('PlayGraph')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newPhys =true;
    this.$store.commit('navigation/replacePhys', newPhys);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule =true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();

  },
  updated () {
    // MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
  }
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
