// Import the edliy_utils
import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeRightText,
    placeSliderSwitch,
    placeDash,
    placeCircle
} from '../../../common/edliy_utils-bernoulli';
const Boxes = {
  box1: function () {
  var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [0, 20, 20, 0],
  keepaspectratio: true, axis:true, ticks:{visible:false},
  grid:true, showCopyright:false, showNavigation:false,
  pan:{enabled:false}, zoom:{enabled:false}});
  brd1.suspendUpdate();
  brd1.options.board.minimizeReflow = 'none';
  brd1.options.text.highlight =false;
  brd1.options.text.fixed =true;
  brd1.options.text.highlight =false;
  brd1.options.text.cssStyle='fontFamily:Oswald',
  brd1.options.image.highlight =false;
  brd1.options.polygon.highlight =false;
  brd1.options.line.highlight =false;
  brd1.options.point.highlight =false;
  brd1.options.curve.highlight =false;
  brd1.options.slider.highlight =false;
  brd1.options.circle.highlight =false;
  brd1.options.point.showInfoBox =false;
  //brd1.options.image.layer =15;
  brd1.options.point.layer =16;
//brd1.options.layer['image'] =14;
//Title and tetxs
  makeResponsive(brd1);
  placeTitle(brd1, 'Units of Measurement', 'Select a Quantity to See its Units');
  //Variables

  var testX = 17;     //pos x of the image of test
  var testY = 17;

  var sizeTry = 1;    //size og the image of tryMe!
  var sizeTest = 1.5; //Size of the image Test yourself
  var sizeOk = 1;     //size of the Ok and the Cross images

  //Buttons
  var size = 4;       //size of the buttons on the left
  var yf = 12;        //final position of the buttons
  var sep = 1.2;      //separation between the buttons
  var button = [];    //grey button
  var button2 = [];   //red button

  for (i=0; i<4; i++){
      var buttoni = brd1.create('image', ['/assets/button.svg', [0, yf - i*size/sep], [size, size]], {visible: true, fixed: true});
      button.push(buttoni);
  }

  for (i=0; i<4; i++){
      var buttoni = brd1.create('image', ['/assets/button2.svg', [0, yf - i*size/sep], [size, size]], {visible: false, fixed: true});
      button2.push(buttoni);
  }

  //Texts on buttons
  var txtButton0 = brd1.create('text', [size/2 - 0.1, yf - 0*size/sep + size/1.8,  'Length'],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);
  var txtButton1 = brd1.create('text', [size/2 - 0.1, yf - 1*size/sep + size/2,  'Mass'],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);
  var txtButton2 = brd1.create('text', [size/2 - 0.1, yf - 2*size/sep + size/2,  'Time'],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);
  var txtButton3 = brd1.create('text', [size/2 - 0.1, yf - 3*size/sep + size/2,  'Temperature'],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);

  //Polygon
  var x0_pol = 6;
  var y0_pol = 13;
  var w_pol = 2;
  var h_pol = 1.5;
  var polygon = [];

  for (i=0; i<7; i++){
      var polygon1 = brd1.create('polygon', [[x0_pol +i*w_pol , y0_pol], [x0_pol +i*w_pol + w_pol, y0_pol], [x0_pol +i*w_pol + w_pol, y0_pol + h_pol], [x0_pol +i*w_pol, y0_pol + h_pol]], {visible: false, vertices:{visible:false, fixed:true}, borders:{visible:false, strokeColor: 'black', strokeWidth: 3}, fillColor: 'grey', fillOpacity:0.05});
      polygon.push(polygon1);
  }

  //Length
      var x0_tape = 6;
      var y0_tape = 4;
      var size_tape = 3;

      var im_tape = brd1.create('image', ['/assets/tape-measure.svg', [x0_tape, y0_tape], [size_tape, size_tape]], {visible: false, fixed: true});
      var lineAttr = brd1.create('segment', [[function(){return im_tape.X() +size_tape},function(){return im_tape.Y()+1}], [25, function(){return im_tape.Y()+1}]], {visible: false, fixed: true, strokeColor: 'black', strokeWidth: 3});
      var pt1 = brd1.create('glider', [10,5, lineAttr],{snapToGrid:true,face:'o' , name:'Drag Me!', size:2, withLabel:true, fixed:false, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
      var line_with_ticks = brd1.create('segment', [[function(){return im_tape.X() +size_tape},function(){return im_tape.Y()+1}], [function(){return pt1.X()}, function(){return pt1.Y() }]], {visible: false, fixed: true, strokeColor: 'black', strokeWidth: 1});
      var ticks = brd1.create('ticks', [line_with_ticks], {visible: false, ticksDistance: 2})
      var pol_tape = brd1.create('polygon', [[function(){return im_tape.X()+size_tape},function(){return im_tape.Y()+0.4}], [function(){return pt1.X()},function(){return im_tape.Y()+0.4}], pt1, [function(){return im_tape.X()+size_tape},function(){return pt1.Y()}]], {visible: false, vertices:{visible:false, fixed:true}, borders:{visible:false, strokeColor: 'black', strokeWidth: 3}, fillColor: '#FF9801', fillOpacity:1});

      var meters = function(){return pt1.X() - (x0_tape+size_tape)};

      var txt00_length = brd1.create('text', [function(){return pt1.X() - meters()/2}, function(){return pt1.Y()+ 0.5},  function(){return meters().toFixed(2) + 'm'}],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}},);

      //var length = brd1.create('input', [x0_pol + 0.5*w_pol - 0.7, y0_pol - h_pol/2, '', ''], {fixed: true, cssStyle: 'fontFamily:Oswald; width:7%; background-color:#008CBA;border: 2px solid black;border-radius: 3.5px;', fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}, fixed:true,parse:true});

      //Tetxs inside the polygon
      var txt0_length = brd1.create('text', [x0_pol + 0.5*w_pol, y0_pol + h_pol/2,  'km'],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);
      var txt1_length = brd1.create('text', [x0_pol + 1.5*w_pol, y0_pol + h_pol/2,  'm'],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);
      var txt2_length = brd1.create('text', [x0_pol + 2.5*w_pol, y0_pol + h_pol/2,  'cm'],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);
      var txt3_length = brd1.create('text', [x0_pol + 3.5*w_pol, y0_pol + h_pol/2,  'inch'],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);
      var txt4_length = brd1.create('text', [x0_pol + 4.5*w_pol, y0_pol + h_pol/2,  'foot'],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);
      var txt5_length = brd1.create('text', [x0_pol + 5.5*w_pol, y0_pol + h_pol/2,  ''],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);
      var txt6_length = brd1.create('text', [x0_pol + 6.5*w_pol, y0_pol + h_pol/2,  ''],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);

      var value0_length = brd1.create('text', [x0_pol + 0.5*w_pol, y0_pol - h_pol/2,  function(){return (meters()/1000).toFixed(3)}],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}},);
      var value1_length = brd1.create('text', [x0_pol + 1.5*w_pol, y0_pol - h_pol/2,  function(){return (meters()).toFixed(0)}],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}},);
      var value2_length = brd1.create('text', [x0_pol + 2.5*w_pol, y0_pol - h_pol/2,  function(){return (meters()*100).toFixed(0)}],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}},);
      var value3_length = brd1.create('text', [x0_pol + 3.5*w_pol, y0_pol - h_pol/2,  function(){return (meters()*39.37).toFixed(1)}],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}},);
      var value4_length = brd1.create('text', [x0_pol + 4.5*w_pol, y0_pol - h_pol/2,  function(){return (meters()*3.281).toFixed(1)}],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}},);

  //Mass

      var x0_scale = 9;
      var y0_scale = 3;
      var size_scale = 4.;

      var im_scale = brd1.create('image', ['/assets/scale.svg', [x0_scale, y0_scale], [size_scale, size_scale]], {visible: false, fixed: true});
      var mass = brd1.create('input', [-20, y0_pol - h_pol/2, '', ''], {fixed: true, cssStyle: 'fontFamily:Oswald; width:7%; background-color:#008CBA;border: 2px solid black;border-radius: 3.5px;', fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}, fixed:true,parse:true});
      var weight = brd1.create('image', ['/assets/weight.svg', [function(){return x0_scale + size_scale/2 - size_weight()/2}, y0_scale + size_scale], [function(){return size_weight()}, function(){return size_weight()}]], {visible: false, fixed: true});

      //clock hand
      var pt1_scale = brd1.create('point', [x0_scale + size_scale/2, y0_scale + size_scale/2.5],{color: 'grey', face:'o' , name:'', size:2, withLabel:true, fixed:true, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
      var pt2_scale = brd1.create('point', [x0_scale + size_scale/3.2, y0_scale + size_scale/2.5],{face:'o' , name:'B', size:2, withLabel:true, fixed:true, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
      var pt3_scale = brd1.create('point', [function(){return pt1_scale.X() + radius_mass()*Math.cos(mass.Value()*2*Math.PI/100 - Math.PI/2)}, function(){return pt1_scale.Y() - radius_mass()*Math.sin(mass.Value()*2*Math.PI/100 - Math.PI/2)}],{face:'o' , name:'weee', size:2, withLabel:true, fixed:true, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
      var hand_scale = brd1.create('segment', [pt1_scale, pt3_scale], {visible: false, fixed: true, strokeColor: 'grey', strokeWidth: 3});

      function radius_mass(){return pt1_scale.X() - pt2_scale.X()};

      //Tetxs inside the polygon
      var txt0_mass = brd1.create('text', [x0_pol + 0.5*w_pol, y0_pol + h_pol/2,  'kg'],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);
      var txt1_mass = brd1.create('text', [x0_pol + 1.5*w_pol, y0_pol + h_pol/2,  'lbs'],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);
      var txt2_mass = brd1.create('text', [x0_pol + 2.5*w_pol, y0_pol + h_pol/2,  'ton'],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);
      var txt3_mass = brd1.create('text', [x0_pol + 3.5*w_pol, y0_pol + h_pol/2,  'g'],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);
      var txt4_mass = brd1.create('text', [x0_pol + 4.5*w_pol, y0_pol + h_pol/2,  'mg'],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);

      //var value0_mass = brd1.create('text', [x0_pol + 0.5*w_pol, y0_pol - h_pol/2,  function(){return (mass.Value())}],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}},);
      var value1_mass = brd1.create('text', [x0_pol + 1.5*w_pol, y0_pol - h_pol/2,  function(){return (mass.Value()*2.20462).toFixed(1)}],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}},);
      var value2_mass = brd1.create('text', [x0_pol + 2.5*w_pol, y0_pol - h_pol/2,  function(){return (mass.Value()*0.00110231).toFixed(3)}],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}},);
      var value3_mass = brd1.create('text', [x0_pol + 3.5*w_pol, y0_pol - h_pol/2,  function(){return (mass.Value()*1000).toFixed(0)}],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}},);
      var value4_mass = brd1.create('text', [x0_pol + 4.5*w_pol, y0_pol - h_pol/2,  function(){return (mass.Value()*1000000).toFixed(0)}],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}},);

      function size_weight(){

          var sw = (10+parseInt(mass.Value()))/20;
          if(sw > 3){
              sw = 3
          }
          else if(sw < 1 || mass.Value() == ''){
              sw = 1
          }
          return sw;
      }

  //Time
      var i=0;
      //Polygon
      var x0_pol_time = 6;
      var y0_pol_time = 13;
      var w_pol_time = 2.5;
      var h_pol_time = 1.5;
      var polygon_time = [];

      for (i=0; i<5; i++){
          var polygon1_time = brd1.create('polygon', [[x0_pol_time +i*w_pol_time , y0_pol_time], [x0_pol_time +i*w_pol_time + w_pol_time, y0_pol_time], [x0_pol_time +i*w_pol_time + w_pol_time, y0_pol_time + h_pol_time], [x0_pol_time +i*w_pol_time, y0_pol_time + h_pol_time]], {visible: false, vertices:{visible:false, fixed:true}, borders:{visible:false, strokeColor: 'black', strokeWidth: 3}, fillColor: 'grey', fillOpacity:0.05});
          polygon_time.push(polygon1_time);
      }

      //Images
      var x0_clock = 8;
      var y0_clock = 3;
      var size_clock = 6;

      var im_clock = brd1.create('image', ['/assets/clock.svg', [x0_clock, y0_clock], [size_clock, size_clock]], {visible: false, fixed: true});
      //var seconds = brd1.create('input', [-20, y0_pol_time - h_pol_time/2, '', ''], {fixed: true, cssStyle: 'fontFamily:Oswald; width:7%; background-color:#008CBA;border: 2px solid black;border-radius: 3.5px;', fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}, fixed:true,parse:true});
      var weeks = brd1.create('input', [-20, y0_pol_time - h_pol_time/2, '', ''], {fixed: true, cssStyle: 'fontFamily:Oswald; width:7%; background-color:#008CBA;border: 2px solid black;border-radius: 3.5px;', fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}, fixed:true,parse:true});

      //clock hand
      var pt1_clock = brd1.create('point', [x0_clock + size_clock/2, y0_clock + size_clock/2],{color: 'grey', face:'o' , name:'A', size:2, withLabel:true, fixed:true, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
      var pt2_clock = brd1.create('point', [x0_clock + size_clock - 0.5, y0_clock + size_clock/2],{face:'o' , name:'B', size:2, withLabel:true, fixed:true, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
      var pt3_clock = brd1.create('point', [function(){return pt1_clock.X() + radius_clock()*Math.cos((weeks.Value()*60*60*24*7)*2*Math.PI/60 - Math.PI/2)}, function(){return pt1_clock.Y() - radius_clock()*Math.sin((weeks.Value()*60*60*24*7)*2*Math.PI/60 - Math.PI/2)}],{face:'o' , name:'weee', size:2, withLabel:true, fixed:true, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
      var hand_clock = brd1.create('segment', [pt1_clock, pt3_clock], {visible: false, fixed: true, strokeColor: 'grey', strokeWidth: 5,
                                                                      highlightStrokeWidth: 2,
                                                                      lastArrow: {type: 2, size: 5, highlightSize: 6},
                                                                      touchLastPoint: false,
                                                                      firstArrow: {type: 2, size: 0}
      });

      function radius_clock(){return pt2_clock.X() - pt1_clock.X()};

      //Tetxs inside the polygon
      var txt0_time = brd1.create('text', [x0_pol_time + 0.5*w_pol_time, y0_pol_time + h_pol_time/2,  'weeks'],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);
      var txt1_time = brd1.create('text', [x0_pol_time + 1.5*w_pol_time, y0_pol_time + h_pol_time/2,  'days'],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);
      var txt2_time = brd1.create('text', [x0_pol_time + 2.5*w_pol_time, y0_pol_time + h_pol_time/2,  'hours'],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);
      var txt3_time = brd1.create('text', [x0_pol_time + 3.5*w_pol_time, y0_pol_time + h_pol_time/2,  'minutes'],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);
      var txt4_time = brd1.create('text', [x0_pol_time + 4.5*w_pol_time, y0_pol_time + h_pol_time/2,  'seconds'],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);

      var value1_time = brd1.create('text', [x0_pol_time + 1.5*w_pol_time, y0_pol_time - h_pol_time/2,  function(){return ((weeks.Value()*7).toFixed(0) )}],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}},);
      var value2_time = brd1.create('text', [x0_pol_time + 2.5*w_pol_time, y0_pol_time - h_pol_time/2,  function(){return (weeks.Value()*7*24).toFixed(0)}],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}},);
      var value3_time = brd1.create('text', [x0_pol_time + 3.5*w_pol_time, y0_pol_time - h_pol_time/2,  function(){return (weeks.Value()*7*24*60).toFixed(0)}],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}},);
      var value4_time = brd1.create('text', [x0_pol_time + 4.5*w_pol_time, y0_pol_time - h_pol_time/2,  function(){return (weeks.Value()*7*24*60*60).toFixed(0)}],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}},);

  //Temperature
      var x0_temp = 8;
      var y0_temp = 3;
      var size_temp = 2;
      var sep_temp = 3;
      var esc = 100;

      var im1_temp = brd1.create('image', ['/assets/thermometer-1.svg', [x0_temp, y0_temp], [size_temp, size_temp]], {visible: false, fixed: true});
      var im2_temp = brd1.create('image', ['/assets/thermometer-1.svg', [x0_temp+sep_temp, y0_temp], [size_temp, size_temp]], {visible: false, fixed: true});
      var im3_temp = brd1.create('image', ['/assets/thermometer-1.svg', [x0_temp+2*sep_temp, y0_temp], [size_temp, size_temp]], {visible: false, fixed: true});

      var seg_glider = brd1.create('segment', [[function(){return im1_temp.X() + size_temp/2}, function(){return im1_temp.Y() + size_temp}], [function(){return im1_temp.X() + size_temp/2}, function(){return im1_temp.Y()  + size_temp+ 10}]], {visible: false, fixed: true, strokeColor: '#F44335', strokeWidth: 3});
      var pt_celsius = brd1.create('glider', [0,10, seg_glider],{color: 'grey', face:'o' , name:'°C', size:5, withLabel:true, fixed:false, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});

      var pt0celsius = brd1.create('point', [function(){return im1_temp.X() + size_temp/2}, function(){return im1_temp.Y()-2.27 + size_temp + seg_glider.L()/2}],{color: 'grey', face:'o' , name:'0', size:1, withLabel:true, fixed:false, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
      var pt_kelvin = brd1.create('point', [function(){return im2_temp.X() + size_temp/2}, function(){return pt0celsius.Y() + Temperature_kelvin()/esc}],{color: 'grey', face:'o' , name:'K', size:1, withLabel:true, fixed:false, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
      var pt_fahrenheit = brd1.create('point', [function(){return im3_temp.X() + size_temp/2}, function(){return pt0celsius.Y() + Temperature_fahrenheit()/esc}],{color: 'grey', face:'o' , name:'°F', size:1, withLabel:true, fixed:false, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});

      var seg1 = brd1.create('segment', [[function(){return im1_temp.X() + size_temp/2}, function(){return im1_temp.Y() + size_temp}], [function(){return im1_temp.X() + size_temp/2}, function(){return im1_temp.Y()  + size_temp+ 10}]], {visible: false, fixed: true, strokeColor: function(){if(Temperature_celsius() > 0){return '#F44335'} else if(Temperature_celsius() <0){return 'blue'} else{return 'grey'}}, strokeWidth: 4});
      var seg2 = brd1.create('segment', [[function(){return im2_temp.X() + size_temp/2}, function(){return im2_temp.Y() + size_temp}], [function(){return im2_temp.X() + size_temp/2}, function(){return im2_temp.Y()  + size_temp+ 10}]], {visible: false, fixed: true, strokeColor: function(){if(Temperature_celsius() > 0){return '#F44335'} else if(Temperature_celsius() <0){return 'blue'} else{return 'grey'}}, strokeWidth: 4});
      var seg3 = brd1.create('segment', [[function(){return im3_temp.X() + size_temp/2}, function(){return im3_temp.Y() + size_temp}], [function(){return im3_temp.X() + size_temp/2}, function(){return im3_temp.Y()  + size_temp+ 10}]], {visible: false, fixed: true, strokeColor: function(){if(Temperature_celsius() > 0){return '#F44335'} else if(Temperature_celsius() <0){return 'blue'} else{return 'grey'}}, strokeWidth: 4});

      var value0_temp = brd1.create('text', [im1_temp.X() + size_temp/2, y0_temp - 1, function(){return Temperature_celsius().toFixed(1) + '°C'}],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);
      var value1_temp = brd1.create('text', [im2_temp.X() + size_temp/2, y0_temp - 1, function(){return Temperature_kelvin().toFixed(1) + 'K'}],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);
      var value2_temp = brd1.create('text', [im3_temp.X() + size_temp/2, y0_temp - 1, function(){return Temperature_fahrenheit().toFixed(1) + '°F'}],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);


      function Temperature_celsius(){
          return (pt_celsius.Y() - pt0celsius.Y())*esc ;
      }

      function Temperature_kelvin(){
          return Temperature_celsius() + 273.15 ;
      }

      function Temperature_fahrenheit(){
          return (Temperature_celsius()*9/5) + 32 ;
      }

  //

  //Images
  var tryMe_length = brd1.create('image', ['/assets/tap.svg', [50,50], [sizeTry, sizeTry]], {visible: false, fixed: true});
  var tryMe_mass = brd1.create('image', ['/assets/tap.svg', [50,50], [sizeTry, sizeTry]], {visible: true, fixed: true});
  var tryMe_time = brd1.create('image', ['/assets/tap.svg', [50,50], [sizeTry, sizeTry]], {visible: false, fixed: true});
  var tryMe_temp = brd1.create('image', ['/assets/tap.svg', [50,50], [sizeTry, sizeTry]], {visible: false, fixed: true});

  var test_mode_length = brd1.create('image', ['/assets/blue.svg', [50,50], [sizeTest, sizeTest]], {visible: false, fixed: true});
  var test_mode_mass = brd1.create('image', ['/assets/blue.svg', [50,50], [sizeTest, sizeTest]], {visible: false, fixed: true});
  var test_mode_time = brd1.create('image', ['/assets/blue.svg', [50,50], [sizeTest, sizeTest]], {visible: false, fixed: true});
  var test_mode_temp = brd1.create('image', ['/assets/blue.svg', [50,50], [sizeTest, sizeTest]], {visible: false, fixed: true});

  var learn_mode_length = brd1.create('image', ['/assets/red.svg', [50,50], [sizeTest, sizeTest]], {visible: false, fixed: true});
  var learn_mode_mass = brd1.create('image', ['/assets/red.svg', [50,50], [sizeTest, sizeTest]], {visible: false, fixed: true});
  var learn_mode_time = brd1.create('image', ['/assets/red.svg', [50,50], [sizeTest, sizeTest]], {visible: false, fixed: true});
  var learn_mode_temp = brd1.create('image', ['/assets/red.svg', [50,50], [sizeTest, sizeTest]], {visible: false, fixed: true});

  var ok_length0 = brd1.create('image', ['/assets/check.svg', [x0_pol + 0.5*w_pol - sizeOk/2, y0_pol - h_pol - sizeOk], [sizeOk, sizeOk]], {visible: false, fixed: true});
  var ok_length2 = brd1.create('image', ['/assets/check.svg', [x0_pol + 2.5*w_pol - sizeOk/2, y0_pol - h_pol - sizeOk], [sizeOk, sizeOk]], {visible: false, fixed: true});
  var ok_length3 = brd1.create('image', ['/assets/check.svg', [x0_pol + 3.5*w_pol - sizeOk/2, y0_pol - h_pol - sizeOk], [sizeOk, sizeOk]], {visible: false, fixed: true});
  var ok_length4 = brd1.create('image', ['/assets/check.svg', [x0_pol + 4.5*w_pol - sizeOk/2, y0_pol - h_pol - sizeOk], [sizeOk, sizeOk]], {visible: false, fixed: true});

  var ok_mass1 = brd1.create('image', ['/assets/check.svg', [x0_pol + 1.5*w_pol - sizeOk/2, y0_pol - h_pol - sizeOk], [sizeOk, sizeOk]], {visible: false, fixed: true});
  var ok_mass2 = brd1.create('image', ['/assets/check.svg', [x0_pol + 2.5*w_pol - sizeOk/2, y0_pol - h_pol - sizeOk], [sizeOk, sizeOk]], {visible: false, fixed: true});
  var ok_mass3 = brd1.create('image', ['/assets/check.svg', [x0_pol + 3.5*w_pol - sizeOk/2, y0_pol - h_pol - sizeOk], [sizeOk, sizeOk]], {visible: false, fixed: true});
  var ok_mass4 = brd1.create('image', ['/assets/check.svg', [x0_pol + 4.5*w_pol - sizeOk/2, y0_pol - h_pol - sizeOk], [sizeOk, sizeOk]], {visible: false, fixed: true});

  var ok_time1 = brd1.create('image', ['/assets/check.svg', [x0_pol_time + 1.5*w_pol_time - sizeOk/2, y0_pol_time - h_pol_time - sizeOk], [sizeOk, sizeOk]], {visible: false, fixed: true});
  var ok_time2 = brd1.create('image', ['/assets/check.svg', [x0_pol_time + 2.5*w_pol_time - sizeOk/2, y0_pol_time - h_pol_time - sizeOk], [sizeOk, sizeOk]], {visible: false, fixed: true});
  var ok_time3 = brd1.create('image', ['/assets/check.svg', [x0_pol_time + 3.5*w_pol_time - sizeOk/2, y0_pol_time - h_pol_time - sizeOk], [sizeOk, sizeOk]], {visible: false, fixed: true});
  var ok_time4 = brd1.create('image', ['/assets/check.svg', [x0_pol_time + 4.5*w_pol_time - sizeOk/2, y0_pol_time - h_pol_time - sizeOk], [sizeOk, sizeOk]], {visible: false, fixed: true});

  var ok_temp2 = brd1.create('image', ['/assets/check.svg', [im2_temp.X() + size_temp/2 - sizeOk/2, y0_temp - 3*sizeOk], [sizeOk, sizeOk]], {visible: false, fixed: true});
  var ok_temp3 = brd1.create('image', ['/assets/check.svg', [im3_temp.X() + size_temp/2 - sizeOk/2, y0_temp - 3*sizeOk], [sizeOk, sizeOk]], {visible: false, fixed: true});

  var cross_length0 = brd1.create('image', ['/assets/cross.svg', [function(){return ok_length0.X()}, function(){return ok_length0.Y()}], [sizeOk, sizeOk]], {visible: false, fixed: true});
  var cross_length2 = brd1.create('image', ['/assets/cross.svg', [function(){return ok_length2.X()}, function(){return ok_length2.Y()}], [sizeOk, sizeOk]], {visible: false, fixed: true});
  var cross_length3 = brd1.create('image', ['/assets/cross.svg', [function(){return ok_length3.X()}, function(){return ok_length3.Y()}], [sizeOk, sizeOk]], {visible: false, fixed: true});
  var cross_length4 = brd1.create('image', ['/assets/cross.svg', [function(){return ok_length4.X()}, function(){return ok_length4.Y()}], [sizeOk, sizeOk]], {visible: false, fixed: true});

  var cross_mass1 = brd1.create('image', ['/assets/cross.svg', [function(){return ok_mass1.X()}, function(){return ok_mass1.Y()}], [sizeOk, sizeOk]], {visible: false, fixed: true});
  var cross_mass2 = brd1.create('image', ['/assets/cross.svg', [function(){return ok_mass2.X()}, function(){return ok_mass2.Y()}], [sizeOk, sizeOk]], {visible: false, fixed: true});
  var cross_mass3 = brd1.create('image', ['/assets/cross.svg', [function(){return ok_mass3.X()}, function(){return ok_mass3.Y()}], [sizeOk, sizeOk]], {visible: false, fixed: true});
  var cross_mass4 = brd1.create('image', ['/assets/cross.svg', [function(){return ok_mass4.X()}, function(){return ok_mass4.Y()}], [sizeOk, sizeOk]], {visible: false, fixed: true});

  var cross_time1 = brd1.create('image', ['/assets/cross.svg', [function(){return ok_time1.X()}, function(){return ok_time1.Y()}], [sizeOk, sizeOk]], {visible: false, fixed: true});
  var cross_time2 = brd1.create('image', ['/assets/cross.svg', [function(){return ok_time2.X()}, function(){return ok_time2.Y()}], [sizeOk, sizeOk]], {visible: false, fixed: true});
  var cross_time3 = brd1.create('image', ['/assets/cross.svg', [function(){return ok_time3.X()}, function(){return ok_time3.Y()}], [sizeOk, sizeOk]], {visible: false, fixed: true});
  var cross_time4 = brd1.create('image', ['/assets/cross.svg', [function(){return ok_time4.X()}, function(){return ok_time4.Y()}], [sizeOk, sizeOk]], {visible: false, fixed: true});

  var cross_temp2 = brd1.create('image', ['/assets/cross.svg', [function(){return ok_temp2.X()}, function(){return ok_temp2.Y()}], [sizeOk, sizeOk]], {visible: false, fixed: true});
  var cross_temp3 = brd1.create('image', ['/assets/cross.svg', [function(){return ok_temp3.X()}, function(){return ok_temp3.Y()}], [sizeOk, sizeOk]], {visible: false, fixed: true});

  //Inputs
  var input_length0 = brd1.create('input', [50,50, '', ''], {fixed: true, cssStyle: 'fontFamily:Oswald; width:7%; background-color:#008CBA;border: 2px solid black;border-radius: 3.5px;', fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}, fixed:true,parse:true});
  var input_length2 = brd1.create('input', [50,50, '', ''], {fixed: true, cssStyle: 'fontFamily:Oswald; width:7%; background-color:#008CBA;border: 2px solid black;border-radius: 3.5px;', fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}, fixed:true,parse:true});
  var input_length3 = brd1.create('input', [50,50, '', ''], {fixed: true, cssStyle: 'fontFamily:Oswald; width:7%; background-color:#008CBA;border: 2px solid black;border-radius: 3.5px;', fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}, fixed:true,parse:true});
  var input_length4 = brd1.create('input', [50,50, '', ''], {fixed: true, cssStyle: 'fontFamily:Oswald; width:7%; background-color:#008CBA;border: 2px solid black;border-radius: 3.5px;', fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}, fixed:true,parse:true});

  var input_mass1 = brd1.create('input', [50,50, '', ''], {fixed: true, cssStyle: 'fontFamily:Oswald; width:7%; background-color:#008CBA;border: 2px solid black;border-radius: 3.5px;', fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}, fixed:true,parse:true});
  var input_mass2 = brd1.create('input', [50,50, '', ''], {fixed: true, cssStyle: 'fontFamily:Oswald; width:7%; background-color:#008CBA;border: 2px solid black;border-radius: 3.5px;', fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}, fixed:true,parse:true});
  var input_mass3 = brd1.create('input', [50,50, '', ''], {fixed: true, cssStyle: 'fontFamily:Oswald; width:7%; background-color:#008CBA;border: 2px solid black;border-radius: 3.5px;', fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}, fixed:true,parse:true});
  var input_mass4 = brd1.create('input', [50,50, '', ''], {fixed: true, cssStyle: 'fontFamily:Oswald; width:7%; background-color:#008CBA;border: 2px solid black;border-radius: 3.5px;', fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}, fixed:true,parse:true});

  var input_time1 = brd1.create('input', [50,50, '', ''], {fixed: true, cssStyle: 'fontFamily:Oswald; width:7%; background-color:#008CBA;border: 2px solid black;border-radius: 3.5px;', fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}, fixed:true,parse:true});
  var input_time2 = brd1.create('input', [50,50, '', ''], {fixed: true, cssStyle: 'fontFamily:Oswald; width:7%; background-color:#008CBA;border: 2px solid black;border-radius: 3.5px;', fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}, fixed:true,parse:true});
  var input_time3 = brd1.create('input', [50,50, '', ''], {fixed: true, cssStyle: 'fontFamily:Oswald; width:7%; background-color:#008CBA;border: 2px solid black;border-radius: 3.5px;', fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}, fixed:true,parse:true});
  var input_time4 = brd1.create('input', [50,50, '', ''], {fixed: true, cssStyle: 'fontFamily:Oswald; width:7%; background-color:#008CBA;border: 2px solid black;border-radius: 3.5px;', fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}, fixed:true,parse:true});

  var input_temp2 = brd1.create('input', [50,50, '', ''], {fixed: true, cssStyle: 'fontFamily:Oswald; width:7%; background-color:#008CBA;border: 2px solid black;border-radius: 3.5px;', fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}, fixed:true,parse:true});
  var input_temp3 = brd1.create('input', [50,50, '', ''], {fixed: true, cssStyle: 'fontFamily:Oswald; width:7%; background-color:#008CBA;border: 2px solid black;border-radius: 3.5px;', fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}, fixed:true,parse:true});

  //Functions

  function showLength(){

      button2[0].setAttribute({visible: true});
      tryMe_length.moveTo([testX + sizeTest, testY], 10);
      learn_mode_length.moveTo([testX, testY], 10);
      test_mode_length.moveTo([testX, testY], 10);

      for (i=0; i<5; i++){
          polygon[i].setAttribute({visible:true, borders: {visible: true}});
      }

      im_tape.setAttribute({visible:true});
      pt1.setAttribute({visible:true});
      line_with_ticks.setAttribute({visible:true});
      ticks.setAttribute({visible:true});
      pol_tape.setAttribute({visible:true});

      txt00_length.setAttribute({visible:true});
      txt0_length.setAttribute({visible:true});
      txt1_length.setAttribute({visible:true});
      txt2_length.setAttribute({visible:true});
      txt3_length.setAttribute({visible:true});
      txt4_length.setAttribute({visible:true});
      txt5_length.setAttribute({visible:true});
      txt6_length.setAttribute({visible:true});

      value0_length.setAttribute({visible:true});
      value1_length.setAttribute({visible:true});
      value2_length.setAttribute({visible:true});
      value3_length.setAttribute({visible:true});
      value4_length.setAttribute({visible:true});
  }

  function showMass(){

      button2[1].setAttribute({visible: true});
      tryMe_mass.moveTo([testX + sizeTest, testY], 10);
      learn_mode_mass.moveTo([testX, testY], 10);
      test_mode_mass.moveTo([testX, testY], 10);

      for (i=0; i<5; i++){
          polygon[i].setAttribute({visible:true, borders: {visible: true}});
      }

      im_scale.setAttribute({visible:true});
      mass.moveTo([x0_pol + 0.5*w_pol - 0.7, y0_pol - h_pol/2],10);
      weight.setAttribute({visible:true});
      pt1_scale.setAttribute({visible:true});
      hand_scale.setAttribute({visible:true});

      txt0_mass.setAttribute({visible:true});
      txt1_mass.setAttribute({visible:true});
      txt2_mass.setAttribute({visible:true});
      txt3_mass.setAttribute({visible:true});
      txt4_mass.setAttribute({visible:true});

      value1_mass.setAttribute({visible:true});
      value2_mass.setAttribute({visible:true});
      value3_mass.setAttribute({visible:true});
      value4_mass.setAttribute({visible:true});
  }

  function showTime(){

      button2[2].setAttribute({visible: true});
      tryMe_time.moveTo([testX + sizeTest, testY], 10);
      learn_mode_time.moveTo([testX, testY], 10);
      test_mode_time.moveTo([testX, testY], 10);


      for (i=0; i<5; i++){
          polygon_time[i].setAttribute({visible:true, borders: {visible: true}});
      }

      im_clock.setAttribute({visible:true});
      weeks.moveTo([x0_pol_time + 0.5*w_pol_time - 0.7, y0_pol_time - h_pol_time/2], 10);
      hand_clock.setAttribute({visible:true});

      txt0_time.setAttribute({visible:true});
      txt1_time.setAttribute({visible:true});
      txt2_time.setAttribute({visible:true});
      txt3_time.setAttribute({visible:true});
      txt4_time.setAttribute({visible:true});

      value1_time.setAttribute({visible:true});
      value2_time.setAttribute({visible:true});
      value3_time.setAttribute({visible:true});
      value4_time.setAttribute({visible:true});
  }

  function showTemperature(){

      button2[3].setAttribute({visible: true});
      tryMe_temp.moveTo([testX + sizeTest, testY], 10);
      learn_mode_temp.moveTo([testX, testY], 10);
      test_mode_temp.moveTo([testX, testY], 10);

      im1_temp.setAttribute({visible:true});
      im2_temp.setAttribute({visible:true});
      im3_temp.setAttribute({visible:true});
      pt0celsius.setAttribute({visible:true});
      pt_celsius.setAttribute({visible:true});
      pt_kelvin.setAttribute({visible:true});
      pt_fahrenheit.setAttribute({visible:true});
      seg1.setAttribute({visible:true});
      seg2.setAttribute({visible:true});
      seg3.setAttribute({visible:true});
      value0_temp.setAttribute({visible:true});
      value1_temp.setAttribute({visible:true});
      value2_temp.setAttribute({visible:true});
  }

  function hideEverything(){

      for (i=0; i<4; i++){
          button2[i].setAttribute({visible: false});
      }

      for (i=0; i<5; i++){
          polygon[i].setAttribute({visible:false, borders: {visible: false}});
      }

      //Length
      learn_mode_length.moveTo([50,50], 10);
      test_mode_length.moveTo([50,50], 10);

      im_tape.moveTo([x0_tape, y0_tape], 10);
      im_tape.setAttribute({visible:false});
      pt1.setAttribute({visible:false});
      pt1.moveTo([10,4],10);
      line_with_ticks.setAttribute({visible:false});
      ticks.setAttribute({visible:false});
      pol_tape.setAttribute({visible:false});

      txt00_length.setAttribute({visible:false});
      txt0_length.setAttribute({visible:false});
      txt1_length.setAttribute({visible:false});
      txt2_length.setAttribute({visible:false});
      txt3_length.setAttribute({visible:false});
      txt4_length.setAttribute({visible:false});
      txt5_length.setAttribute({visible:false});
      txt6_length.setAttribute({visible:false});

      value0_length.setAttribute({visible:false});
      value1_length.setAttribute({visible:false});
      value2_length.setAttribute({visible:false});
      value3_length.setAttribute({visible:false});
      value4_length.setAttribute({visible:false});

      //Mass
      learn_mode_mass.moveTo([50,50], 10);
      test_mode_mass.moveTo([50,50], 10);

      im_scale.setAttribute({visible:false});
      mass.moveTo([-20, y0_pol - h_pol/2],10);
      weight.setAttribute({visible:false});
      pt1_scale.setAttribute({visible:false});
      hand_scale.setAttribute({visible:false});

      txt0_mass.setAttribute({visible:false});
      txt1_mass.setAttribute({visible:false});
      txt2_mass.setAttribute({visible:false});
      txt3_mass.setAttribute({visible:false});
      txt4_mass.setAttribute({visible:false});

      value1_mass.setAttribute({visible:false});
      value2_mass.setAttribute({visible:false});
      value3_mass.setAttribute({visible:false});
      value4_mass.setAttribute({visible:false});

      //Time
      learn_mode_time.moveTo([50,50], 10);
      test_mode_time.moveTo([50,50], 10);

      for (i=0; i<5; i++){
          polygon_time[i].setAttribute({visible:false, borders: {visible: false}});
      }

      im_clock.setAttribute({visible:false});
      weeks.moveTo([-20, y0_pol_time - h_pol_time/2], 10);
      hand_clock.setAttribute({visible:false});

      txt0_time.setAttribute({visible:false});
      txt1_time.setAttribute({visible:false});
      txt2_time.setAttribute({visible:false});
      txt3_time.setAttribute({visible:false});
      txt4_time.setAttribute({visible:false});

      value1_time.setAttribute({visible:false});
      value2_time.setAttribute({visible:false});
      value3_time.setAttribute({visible:false});
      value4_time.setAttribute({visible:false});

      //Temperature
      learn_mode_temp.moveTo([50,50], 10);
      test_mode_temp.moveTo([50,50], 10);

      im1_temp.setAttribute({visible:false});
      im2_temp.setAttribute({visible:false});
      im3_temp.setAttribute({visible:false});
      pt0celsius.setAttribute({visible:false});
      pt_celsius.setAttribute({visible:false});
      pt_kelvin.setAttribute({visible:false});
      pt_fahrenheit.setAttribute({visible:false});
      seg1.setAttribute({visible:false});
      seg2.setAttribute({visible:false});
      seg3.setAttribute({visible:false});
      value0_temp.setAttribute({visible:false});
      value1_temp.setAttribute({visible:false});
      value2_temp.setAttribute({visible:false});

      bye_length();
      bye_mass();
      bye_time();
      bye_temp();


  }

  //Test yourself functions
  //Length
  function check_length(){

      ok_length0.setAttribute({visible: false});
      ok_length2.setAttribute({visible: false});
      ok_length3.setAttribute({visible: false});
      ok_length4.setAttribute({visible: false});

      cross_length0.setAttribute({visible: false});
      cross_length2.setAttribute({visible: false});
      cross_length3.setAttribute({visible: false});
      cross_length4.setAttribute({visible: false});

      if(input_length0.Value() == (meters()/1000).toFixed(3)){
          ok_length0.setAttribute({visible: true});
      }
      else if(input_length0.Value() != (meters()/1000).toFixed(3)){
          cross_length0.setAttribute({visible: true});
      }
      if(input_length2.Value() == (meters()*100).toFixed(0)){
          ok_length2.setAttribute({visible: true});
      }
      else if(input_length2.Value() != (meters()*100).toFixed(0)){
          cross_length2.setAttribute({visible: true});
      }
      if(input_length3.Value() == (meters()*39.37).toFixed(1)){
          ok_length3.setAttribute({visible: true});
      }
      else if(input_length3.Value() != (meters()*39.37).toFixed(1)){
          cross_length3.setAttribute({visible: true});
      }
      if(input_length4.Value() == (meters()*3.281).toFixed(1)){
          ok_length4.setAttribute({visible: true});
      }
      else if(input_length4.Value() != (meters()*3.281).toFixed(1)){
          cross_length4.setAttribute({visible: true});
      }

  }

  function hi_length(){
      learn_mode_length.setAttribute({visible: true});
      test_mode_length.setAttribute({visible: false});
      value0_length.moveTo([50, 50], 10);
      value2_length.moveTo([50, 50], 10);
      value3_length.moveTo([50, 50], 10);
      value4_length.moveTo([50, 50], 10);

      tryMe_length.setAttribute({visible: true});

      input_length0.moveTo([x0_pol + 0.5*w_pol - 0.7, y0_pol - h_pol/2], 10);
      input_length2.moveTo([x0_pol + 2.5*w_pol - 0.7, y0_pol - h_pol/2], 10);
      input_length3.moveTo([x0_pol + 3.5*w_pol - 0.7, y0_pol - h_pol/2], 10);
      input_length4.moveTo([x0_pol + 4.5*w_pol - 0.7, y0_pol - h_pol/2], 10);

  }

  function bye_length(){

      learn_mode_length.setAttribute({visible: false});
      test_mode_length.setAttribute({visible: true});
      ok_length0.setAttribute({visible: false});
      ok_length2.setAttribute({visible: false});
      ok_length3.setAttribute({visible: false});
      ok_length4.setAttribute({visible: false});

      cross_length0.setAttribute({visible: false});
      cross_length2.setAttribute({visible: false});
      cross_length3.setAttribute({visible: false});
      cross_length4.setAttribute({visible: false});

      tryMe_length.setAttribute({visible: false});
      value0_length.moveTo([x0_pol + 0.5*w_pol, y0_pol - h_pol/2], 10);
      value2_length.moveTo([x0_pol + 2.5*w_pol, y0_pol - h_pol/2], 10);
      value3_length.moveTo([x0_pol + 3.5*w_pol, y0_pol - h_pol/2], 10);
      value4_length.moveTo([x0_pol + 4.5*w_pol, y0_pol - h_pol/2], 10);

      input_length0.moveTo([50,50], 10);
      input_length2.moveTo([50,50], 10);
      input_length3.moveTo([50,50], 10);
      input_length4.moveTo([50,50], 10);

  }

  //Mass
  function check_mass(){

      ok_mass1.setAttribute({visible: false});
      ok_mass2.setAttribute({visible: false});
      ok_mass3.setAttribute({visible: false});
      ok_mass4.setAttribute({visible: false});

      cross_mass1.setAttribute({visible: false});
      cross_mass2.setAttribute({visible: false});
      cross_mass3.setAttribute({visible: false});
      cross_mass4.setAttribute({visible: false});

      if(input_mass1.Value() == (mass.Value()*2.20462).toFixed(1)){
          ok_mass1.setAttribute({visible: true});
      }
      else if(input_mass1.Value() != (mass.Value()*2.20462).toFixed(1)){
          cross_mass1.setAttribute({visible: true});
      }

      if(input_mass2.Value() == (mass.Value()*0.00110231).toFixed(3)){
          ok_mass2.setAttribute({visible: true});
      }
      else if(input_mass2.Value() != (mass.Value()**0.00110231).toFixed(3)){
          cross_mass2.setAttribute({visible: true});
      }

      if(input_mass3.Value() == (mass.Value()*1000).toFixed(0)){
          ok_mass3.setAttribute({visible: true});
      }
      else if(input_mass3.Value() != (mass.Value()*1000).toFixed(0)){
          cross_mass3.setAttribute({visible: true});
      }

      if(input_mass4.Value() == (mass.Value()*1000000).toFixed(0)){
          ok_mass4.setAttribute({visible: true});
      }
      else if(input_mass4.Value() != (mass.Value()*1000000).toFixed(0)){
          cross_mass4.setAttribute({visible: true});
      }

  }

  function hi_mass(){ //Show the things when I click to go back to learn - mode INSIDE THE MASS
      learn_mode_mass.setAttribute({visible: true});
      test_mode_mass.setAttribute({visible: false});
      value1_mass.moveTo([50, 50], 10);
      value2_mass.moveTo([50, 50], 10);
      value3_mass.moveTo([50, 50], 10);
      value4_mass.moveTo([50, 50], 10);

      tryMe_mass.setAttribute({visible: true});

      input_mass1.moveTo([x0_pol + 1.5*w_pol - 0.7, y0_pol - h_pol/2], 10);
      input_mass2.moveTo([x0_pol + 2.5*w_pol - 0.7, y0_pol - h_pol/2], 10);
      input_mass3.moveTo([x0_pol + 3.5*w_pol - 0.7, y0_pol - h_pol/2], 10);
      input_mass4.moveTo([x0_pol + 4.5*w_pol - 0.7, y0_pol - h_pol/2], 10);
  }

  function bye_mass(){  //Hide the things when I click to go back to learn - mode INSIDE THE MASS

      learn_mode_mass.setAttribute({visible: false});
      test_mode_mass.setAttribute({visible: true});
      ok_mass1.setAttribute({visible: false});
      ok_mass2.setAttribute({visible: false});
      ok_mass3.setAttribute({visible: false});
      ok_mass4.setAttribute({visible: false});

      cross_mass1.setAttribute({visible: false});
      cross_mass2.setAttribute({visible: false});
      cross_mass3.setAttribute({visible: false});
      cross_mass4.setAttribute({visible: false});

      tryMe_mass.setAttribute({visible: false});
      value1_mass.moveTo([x0_pol + 1.5*w_pol, y0_pol - h_pol/2], 10);
      value2_mass.moveTo([x0_pol + 2.5*w_pol, y0_pol - h_pol/2], 10);
      value3_mass.moveTo([x0_pol + 3.5*w_pol, y0_pol - h_pol/2], 10);
      value4_mass.moveTo([x0_pol + 4.5*w_pol, y0_pol - h_pol/2], 10);

      input_mass1.moveTo([50,50], 10);
      input_mass2.moveTo([50,50], 10);
      input_mass3.moveTo([50,50], 10);
      input_mass4.moveTo([50,50], 10);
  }

  //Time
  function check_time(){

      ok_time1.setAttribute({visible: false});
      ok_time2.setAttribute({visible: false});
      ok_time3.setAttribute({visible: false});
      ok_time4.setAttribute({visible: false});

      cross_time1.setAttribute({visible: false});
      cross_time2.setAttribute({visible: false});
      cross_time3.setAttribute({visible: false});
      cross_time4.setAttribute({visible: false});

      if(input_time1.Value() == (weeks.Value()*7).toFixed(0)){
          ok_time1.setAttribute({visible: true});
      }
      else if(input_time1.Value() != (weeks.Value()*7).toFixed(0)){
          cross_time1.setAttribute({visible: true});
      }

      if(input_time2.Value() == (weeks.Value()*7*24).toFixed(0)){
          ok_time2.setAttribute({visible: true});
      }
      else if(input_time2.Value() != (weeks.Value()*7*24).toFixed(0)){
          cross_time2.setAttribute({visible: true});
      }

      if(input_time3.Value() == (weeks.Value()*7*24*60).toFixed(0)){
          ok_time3.setAttribute({visible: true});
      }
      else if(input_time3.Value() != (weeks.Value()*7*24*60).toFixed(0)){
          cross_time3.setAttribute({visible: true});
      }

      if(input_time4.Value() == (weeks.Value()*7*24*60*60).toFixed(0)){
          ok_time4.setAttribute({visible: true});
      }
      else if(input_time4.Value() != (weeks.Value()*7*24*24*60).toFixed(0)){
          cross_time4.setAttribute({visible: true});
      }

  }

  function hi_time(){
      learn_mode_time.setAttribute({visible: true});
      test_mode_time.setAttribute({visible: false});
      value1_time.moveTo([50, 50], 10);
      value2_time.moveTo([50, 50], 10);
      value3_time.moveTo([50, 50], 10);
      value4_time.moveTo([50, 50], 10);

      tryMe_time.setAttribute({visible: true});

      input_time1.moveTo([x0_pol_time + 1.5*w_pol_time - 0.7, y0_pol_time - h_pol_time/2], 10);
      input_time2.moveTo([x0_pol_time + 2.5*w_pol_time - 0.7, y0_pol_time - h_pol_time/2], 10);
      input_time3.moveTo([x0_pol_time + 3.5*w_pol_time - 0.7, y0_pol_time - h_pol_time/2], 10);
      input_time4.moveTo([x0_pol_time + 4.5*w_pol_time - 0.7, y0_pol_time - h_pol_time/2], 10);
  }

  function bye_time(){

      learn_mode_time.setAttribute({visible: false});
      test_mode_time.setAttribute({visible: true});
      ok_time1.setAttribute({visible: false});
      ok_time2.setAttribute({visible: false});
      ok_time3.setAttribute({visible: false});
      ok_time4.setAttribute({visible: false});

      cross_time1.setAttribute({visible: false});
      cross_time2.setAttribute({visible: false});
      cross_time3.setAttribute({visible: false});
      cross_time4.setAttribute({visible: false});

      tryMe_time.setAttribute({visible: false});
      value1_time.moveTo([x0_pol_time + 1.5*w_pol_time, y0_pol_time - h_pol_time/2], 10);
      value2_time.moveTo([x0_pol_time + 2.5*w_pol_time , y0_pol_time - h_pol_time/2], 10);
      value3_time.moveTo([x0_pol_time + 3.5*w_pol_time, y0_pol_time - h_pol_time/2], 10);
      value4_time.moveTo([x0_pol_time + 4.5*w_pol_time, y0_pol_time - h_pol_time/2], 10);

      input_time1.moveTo([50,50], 10);
      input_time2.moveTo([50,50], 10);
      input_time3.moveTo([50,50], 10);
      input_time4.moveTo([50,50], 10);
  }

  //Temperature
  function check_temp(){

      ok_temp2.setAttribute({visible: false});
      ok_temp3.setAttribute({visible: false});

      cross_temp2.setAttribute({visible: false});
      cross_temp3.setAttribute({visible: false});

      if(input_temp2.Value() == (Temperature_kelvin()).toFixed(1)){
          ok_temp2.setAttribute({visible: true});
      }
      else if(input_temp2.Value() != (Temperature_kelvin()).toFixed(1)){
          cross_temp2.setAttribute({visible: true});
      }

      if(input_temp3.Value() == (Temperature_fahrenheit()).toFixed(1)){
          ok_temp3.setAttribute({visible: true});
      }
      else if(input_temp3.Value() != (Temperature_fahrenheit()).toFixed(1)){
          cross_temp3.setAttribute({visible: true});
      }
  }

  function hi_temp(){
      learn_mode_temp.setAttribute({visible: true});
      test_mode_temp.setAttribute({visible: false});
      value1_temp.moveTo([50, 50], 10);
      value2_temp.moveTo([50, 50], 10);

      tryMe_temp.setAttribute({visible: true});

      input_temp2.moveTo([im2_temp.X() + size_temp/2 - 0.7, y0_temp - 1], 10);
      input_temp3.moveTo([im3_temp.X() + size_temp/2 - 0.7, y0_temp - 1], 10);
  }

  function bye_temp(){

      learn_mode_temp.setAttribute({visible: false});
      test_mode_temp.setAttribute({visible: true});
      ok_temp2.setAttribute({visible: false});
      ok_temp3.setAttribute({visible: false});

      cross_temp2.setAttribute({visible: false});
      cross_temp3.setAttribute({visible: false});

      tryMe_temp.setAttribute({visible: false});
      value1_temp.moveTo([im2_temp.X() + size_temp/2, y0_temp - 1], 10);
      value2_temp.moveTo([im3_temp.X() + size_temp/2, y0_temp - 1], 10);

      input_temp2.moveTo([50,50], 10);
      input_temp3.moveTo([50,50], 10);
  }

  //Buttons
  button[0].on('down', hideEverything);
  button[1].on('down', hideEverything);
  button[2].on('down', hideEverything);
  button[3].on('down', hideEverything);

  button[0].on('up', showLength);
  button[1].on('up', showMass);
  button[2].on('up', showTime);
  button[3].on('up', showTemperature);

  //Mouse click
  tryMe_length.on('down',check_length);
  tryMe_mass.on('down',check_mass);
  tryMe_time.on('down',check_time);
  tryMe_temp.on('down',check_temp);

  test_mode_length.on('up', function(){ hi_length();});
  test_mode_mass.on('up', function(){ hi_mass();});
  test_mode_time.on('up', function(){ hi_time();});
  test_mode_temp.on('up', function(){ hi_temp();});

  learn_mode_length.on('down', function(){ bye_length();});
  learn_mode_mass.on('down', function(){ bye_mass();});
  learn_mode_time.on('down', function(){ bye_time();});
  learn_mode_temp.on('down', function(){ bye_temp();});

  //Labels
  tryMe_length.setLabel('Try')
  tryMe_length.label.setAttribute({visible:false, offset:[15, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
  tryMe_length.on('over', function () {this.label.setAttribute({visible:true});});
  tryMe_length.on('out', function () {this.label.setAttribute({visible:false});});

  test_mode_length.setLabel('Test Yourself!')
  test_mode_length.label.setAttribute({visible:false, offset:[-10, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
  test_mode_length.on('over', function () {this.label.setAttribute({visible:true});});
  test_mode_length.on('out', function () {this.label.setAttribute({visible:false});});

  learn_mode_length.setLabel('Learning Mode')
  learn_mode_length.label.setAttribute({visible:false, offset:[-10, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
  learn_mode_length.on('over', function () {this.label.setAttribute({visible:true});});
  learn_mode_length.on('out', function () {this.label.setAttribute({visible:false});});

  tryMe_mass.setLabel('Try')
  tryMe_mass.label.setAttribute({visible:false, offset:[15, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
  tryMe_mass.on('over', function () {this.label.setAttribute({visible:true});});
  tryMe_mass.on('out', function () {this.label.setAttribute({visible:false});});

  test_mode_mass.setLabel('Test Yourself!')
  test_mode_mass.label.setAttribute({visible:false, offset:[-10, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
  test_mode_mass.on('over', function () {this.label.setAttribute({visible:true});});
  test_mode_mass.on('out', function () {this.label.setAttribute({visible:false});});

  learn_mode_mass.setLabel('Learning Mode')
  learn_mode_mass.label.setAttribute({visible:false, offset:[-10, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
  learn_mode_mass.on('over', function () {this.label.setAttribute({visible:true});});
  learn_mode_mass.on('out', function () {this.label.setAttribute({visible:false});});

  tryMe_time.setLabel('Try')
  tryMe_time.label.setAttribute({visible:false, offset:[15, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
  tryMe_time.on('over', function () {this.label.setAttribute({visible:true});});
  tryMe_time.on('out', function () {this.label.setAttribute({visible:false});});

  test_mode_time.setLabel('Test Yourself!')
  test_mode_time.label.setAttribute({visible:false, offset:[-10, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
  test_mode_time.on('over', function () {this.label.setAttribute({visible:true});});
  test_mode_time.on('out', function () {this.label.setAttribute({visible:false});});

  learn_mode_time.setLabel('Learning Mode')
  learn_mode_time.label.setAttribute({visible:false, offset:[-10, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
  learn_mode_time.on('over', function () {this.label.setAttribute({visible:true});});
  learn_mode_time.on('out', function () {this.label.setAttribute({visible:false});});

  tryMe_temp.setLabel('Try')
  tryMe_temp.label.setAttribute({visible:false, offset:[15, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
  tryMe_temp.on('over', function () {this.label.setAttribute({visible:true});});
  tryMe_temp.on('out', function () {this.label.setAttribute({visible:false});});

  test_mode_temp.setLabel('Test Yourself!')
  test_mode_temp.label.setAttribute({visible:false, offset:[-10, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
  test_mode_temp.on('over', function () {this.label.setAttribute({visible:true});});
  test_mode_temp.on('out', function () {this.label.setAttribute({visible:false});});

  learn_mode_temp.setLabel('Learning Mode')
  learn_mode_temp.label.setAttribute({visible:false, offset:[-10, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
  learn_mode_temp.on('over', function () {this.label.setAttribute({visible:true});});
  learn_mode_temp.on('out', function () {this.label.setAttribute({visible:false});});


  brd1.unsuspendUpdate();
    },
}
export default Boxes;
